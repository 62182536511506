












import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import { SwaggerUIBundle, SwaggerUIStandalonePreset } from "@/libs/swagger-ui-dist"
import vSelect from "vue-select";

//const SwaggerUI = require('swagger-ui')
//import SwaggerUI from 'swagger-ui';
import '@libs/swagger-ui-dist/swagger-ui.css';

@Component({
  components: {
vSelect
  }
})
export default class Swagger extends Vue {
  async mounted() {
    const documents = [
      {
        url: this.$api.branch.swagger.Url(),
        name: 'Branch API'
      },
      {
        url: this.$api.candidate.swagger.Url(),
        name: 'Candidate API'
      },
      {
        url: this.$api.employee.swagger.Url(),
        name: 'Employee API'
      },
      {
        url: this.$api.employeecrmaccess.swagger.Url(),
        name: 'Employee CRM Access API'
      },
      {
        url: this.$api.identity.swagger.Url(),
        name: 'Identity'
      },
      {
        url: this.$api.maintenance.swagger.Url(),
        name: 'Maintenance API'
      },
      {
        url: this.$api.portal.swagger.Url(),
        name: 'Portal API'
      },
      {
        url: this.$api.reporting.swagger.Url(),
        name: 'Reporting API'
      },
      {
        url: this.$api.registrations.swagger.Url(),
        name: 'Registrations API'
      },
      {
        url: this.$api.registrationsendtocrm.swagger.Url(),
        name: 'Registration Send to CRM API'
      },
      {
        url: this.$api.talent.swagger.Url(),
        name: 'Talent API'
      }
    ];

    this.apiDocumentUrls = documents;

    this.apiDocumentUrl = this.apiDocumentUrls[0];
    this.reloadSwagger();
  }

  apiDocumentUrl = null;
  apiDocumentUrls: {url: string, name: string}[] = []
  reloadSwagger() {
    SwaggerUIBundle({
      dom_id: '#swagger-ui',
      url: this.apiDocumentUrl.url,
      layout: "BaseLayout"
    });
  }



  @Watch("apiDocumentUrl")
  async watchUrl() {
    this.reloadSwagger()
  }
}
